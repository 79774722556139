.survey-question {
    font-weight: 500;
    line-height: 1.2;
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-size: 1.5rem;
}

.survey-color-tag {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 5px;
    margin: 0.25rem;
    padding: 0rem;
    font-size: 1rem;
    text-align: center;
}
