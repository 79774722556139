
table.roadmap {

    border: 1px solid black;

    th, td {
        border: 1px solid black;
        padding: 0.75rem;
    }

    th {
        font-weight: bold;
        background-color: $gray-200;
        vertical-align: top;
    }

    th.released {
        color: green;
    }

    th.development {
        color: red;
    }

    td {
        vertical-align: top;

        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;

            li {
                padding: 0.75rem 0 0 0;

                .roadmap-tags {
                    margin-left: 1rem;
                }

                .roadmap-tag {
                    font-size: smaller;
                    color: $light;
                    text-transform: uppercase;
                }
            }
        }
    }

}

ul.roadmap-legend {
    list-style-type: none;
    padding: 0;
    margin-left: 1rem;
    margin-bottom: 1rem;

    li {

        padding: 0.125rem 0 0 0;
        margin-left: 1rem;
    }
}
