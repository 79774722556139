html {
    scroll-padding-top: calc(
        69px + 0.5rem
    ); /* Offset for navbar, used for anchor links woth hashes */
}

h1 {
    margin-bottom: 2rem;
}

p {
    @if $paragraph-max-width != 0 {
        max-width: $paragraph-max-width;
    }
}

// NAVBAR

nav.navbar {
    border-bottom: 1px solid $separator-line-color;
}

.navbar-fixed {
    overflow: hidden;
    position: fixed;
    top: 0;
    width: 100%;
    background-color: $white !important;
    z-index: 99;
}

// NAVTREE

@media (min-width: 768px) {
    .navtree-fixed {
        overflow-y: auto;
        background: $white;
        position: fixed;
        top: 69px;
        z-index: 99;
        padding-top: 23px;
        height: calc(100vh - 69px);
        scrollbar-color: #c1c1c1 transparent;
        scrollbar-width: none;
    }

    .content-left-margin {
        margin-left: 25%;
    }

    .navtree-fixed:hover {
        scrollbar-width: thin;
    }
}

@media (min-width: 2048px) {
    .navtree-column {
        // Stop-gap measure for ridiculously wide browser windows.
        // We need to figure something better in the future.
        max-width: 32rem;
    }

    .content-left-margin {
        margin-left: 32rem !important;
    }
}

.navtree {
    list-style: none;

    li {
        list-style: none;
        padding-left: 1rem;
        text-indent: -1rem;
        color: $navbar-light-color;
    }

    li > a {
        color: $navbar-light-color;
    }

    li.active {
        list-style: none;
        padding-left: 1rem;
        text-indent: -1rem;
        color: $navbar-light-active-color;
    }

    li.active > a {
        color: $navbar-light-active-color;
    }

    ul {
        // deeper-level nodes
        // We them indented, but just a little. We want to fit in long labels.
        padding-left: 0.5rem;
    }

    > ul {
        // top-level nodes, we want it closer to margin
        padding-left: 0.5rem;
    }
}

.navbar-brand {
    font-size: 1.75rem;
}

.breadcrumb-item > a {
    color: $navbar-light-color;
}

footer {
    max-height: fit-content;
}

main {
    margin-bottom: 2rem;
    margin-top: 93px;
}

.logo-title {
    // Same margin as ordinary h1 title
    margin-bottom: 2rem;

    img {
        max-height: 5rem;
    }
}

.nobullet {
    list-style: none;
}

// FOOTER

.footer-logo {
    display: inline-block;
    vertical-align: middle;
    width: 170px;
    background-size: cover;
    filter: $footer-logo-filter;
}

.page-footer {
    color: $footer-color;
    background-color: $footer-background-color;
    margin-top: 2rem;
    border-top: 1px solid $separator-line-color !important;

    .footer-body {
        // padding-bottom: 1.25rem;
    }

    .footer-heading {
        font-size: x-large;
        margin-bottom: 1rem;
    }

    .footer-description {
        color: rgba($footer-color, 0.7);
    }

    .footer-list {
    }

    a {
        color: rgba($footer-color, 0.7);
    }

    .social {
        margin-right: 0.5rem;
    }

    .copyright {
        padding-top: 0.5rem;
        border-top: 1px solid $separator-line-color !important;
    }
}

// MISC

div.bottomline {
    font-size: small;
    color: $light;
}

address.author {
    margin-top: -2rem;
    margin-bottom: 2rem;
    font-style: italic;
    font-size: 1.25rem;
}

div.subtitle {
    margin-top: -2rem;
    margin-bottom: 2rem;
    font-size: 1.25rem;
}

div.page-meta {
    margin-top: -2rem;
    margin-bottom: 2rem;
    font-style: italic;
    font-size: 0.75rem;
    color: $light;
}

.max-width {
    max-width: 1440px;
}

.synopsis-table {
    margin-top: 2rem;
    margin-bottom: 3rem;
    border-bottom: $table-border-width solid $table-border-color;

    th,
    td {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }

    th {
        width: 20%;
    }
}

.glossref {
    font-style: italic;
    color: $indigo;
}

.table.release-nav-table {
    margin-bottom: 0rem;
}

.nav.nav-tabs:has(.release-nav-table) {
    margin-bottom: 2rem;
}

img {
    max-width: 100%;
    height: auto;
}

table {
    max-width: 100%;
    overflow-x: auto;
    display: block;
}

body {
    overflow-x: hidden;
    width: 100%;
}
