.edit-buttons {
    margin-top: 1rem;
    display: block;
}

.text-smaller {
    font-size: smaller;
}

.text-gray {
    color: $gray-600;
}

.starfield > a {
    color: inherit;
    text-decoration: inherit;
}

@each $name, $color in $css-named-colors {
    .#{$name} {
        color: $color;
    }
}
