///////////////////////////
// BOOTSTRAP variables
///////////////////////////

// Fonts
$font-family-sans-serif:      'Roboto', 'Open Sans', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-monospace:       'Roboto Mono', SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;


// Colors
$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

$blue:    #2f81d4 !default;
$orange:  #ffb437 !default;
$dark-blue: #1f4288 !default;
$gray-82: #828282 !default;

// Default bootstrap colors: TODO update
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dc3545 !default;
$yellow:  #ffc107 !default;
$green:   #28a745 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;


$primary:       $blue !default;
$secondary:     $orange !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-82 !default;
$dark:          $dark-blue !default;

$theme-colors: (
    "primary":    $primary,
    "secondary":  $secondary,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "light":      $light,
    "dark":       $dark
);

$body-color:                        $black;

$breadcrumb-font-size:              0.75rem;

$breadcrumb-padding-y:              0rem;
$breadcrumb-padding-x:              0rem;
$breadcrumb-item-padding:           .25rem;

$breadcrumb-margin-bottom:          1rem;

$breadcrumb-bg:                     transparent;
$breadcrumb-divider-color:          $gray-600 !default;
$breadcrumb-active-color:           $gray-600 !default;
$breadcrumb-divider:                quote("/") !default;


///////////////////////////
// EVOLVEUM variables
///////////////////////////

// Applied to parahraph (p) and all the paragraph-like structrures (e.g. lists)
// NOT applied to tables, pictures, code listings, etc.
$paragraph-max-width: 80rem;

$separator-line-color: rgba($light, 0.1);

// Dark footer settings
//$footer-color = $white;
//$footer-background-color = $dark;
//$footer-logo-filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(150%);

// White footer settings
$footer-color: $light;
$footer-background-color: transparent;
$footer-logo-filter: saturate(0%) opacity(60%);
