.datatable-config {
    display: none !important;
}

.dataTables_wrapper {
    margin-bottom: 2.5rem;
}

table.dataTable {
    margin-left: 0 !important;
}