.release-dedication-block > table {
    border-collapse: separate;
    border: 0;
    background: none;
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem;

    td.content {
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
        color: $blockquote-cite-font-color;
        border-left: 1px solid #dddddd;

        > :last-child > :last-child {
          margin-bottom: 0;
        }
    }

}

.release-unsupported {
    color: $gray-600;
    th {
        font-weight: normal;
    }
}
