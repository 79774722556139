
.rule {
  float: right;
  width: 30%;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 30px;
  margin-right: 0px;
  padding: 10px;
  border: solid grey 1px;

  title {
    display: block;
    font-weight: bold;
  }

  title::before {
    content: "Simplified Rule";
    display: block;
    background: white;
    margin-top:-20px;
    margin-left:5px;
    padding-left:5px;
    padding-right:5px;
    border: solid grey 1px;
    width: 8em;
  }
}


img.illustration {
  float: right;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 40px;
  margin-right: 0px;
  width: 40%;
}
