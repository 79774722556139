.book-dl-links {
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-top: 1rem;
    margin-bottom: 0.25rem;

    li {
        text-align: center;
        white-space: nowrap;
        display: inline-block;
        margin-left: 1rem;
        margin-right: 1rem;
        font-size: 1.5rem;

        i.icon {
            font-size: 6rem;
            display: block;
        }
    }
}
