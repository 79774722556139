
img.personal-photo {
    float: right;
    width: 30%;
    height: auto;
    margin-left: 15px;
}

ul.personal-links {
    list-style-type: none;
    padding-left: 0rem;
}
